<template>
  <!-- 买机票选地区、时间 -->
  <div>
    <!-- UI+逻辑组件 -->
    <BuyAircraftUI @searchTrain="searchTrain" @chooseCity="chooseCity" :showComeGo="true" />
    <!-- 历史记录组件 -->
    <History ref="history" :historyKey="'aircraftHistory'" @setCity="setCity" />
  </div>
</template>

<script>
import BuyAircraftUI from "./components/BuyAircraftUI";
import History from "@/components/History";
import { mapGetters } from "vuex";
export default {
  name: "BuyAircraft",
  components: { BuyAircraftUI, History },
  computed: {
    ...mapGetters(["aircraftOrderInfo"])
  },
  methods: {
    // 搜索按钮 单程或往返
    searchTrain(type) {
      this.$refs.history.append(this.aircraftOrderInfo);
      this.$router.push(!type ? "/searchAircraftSingle" : "/searchAircraftTo");
    },

    // 选择城市
    chooseCity(vuxFunction) {
      this.$router.push({
        path: "/selectCity",
        query: { function: vuxFunction }
      });
    },

    // 历史记录选择城市
    setCity(data) {
      this.aircraftOrderInfo.to = data.to;
      this.aircraftOrderInfo.from = data.from;
      this.$store.dispatch(
        "aircraft/setAircraftOrderInfo",
        this.aircraftOrderInfo
      );
    }
  }
};
</script>
